import { faGripVertical } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import type { DropResult } from '@hello-pangea/dnd';
import { DragDropContext, Draggable, Droppable } from '@hello-pangea/dnd';
import { yupResolver } from '@hookform/resolvers/yup';
import type { FC } from 'react';
import { useState } from 'react';
import { Controller } from 'react-hook-form';
import type { InferType } from 'yup';
import { array, boolean, mixed, object, string } from 'yup';

import Button from '@/components/Button';
import Checkbox from '@/components/Form/Fields/Checkbox';
import FileInput from '@/components/Form/Fields/FileInput';
import Label from '@/components/Form/Fields/Label';
import MultiLineInput from '@/components/Form/Fields/MultiLineInput';
import Text from '@/components/Text';
import { DocumentsFileTypes } from '@/config/filesTypes';
import useForm from '@/hooks/useForm';

import type { StepsProps } from '../LayoutTemplateRequest';

export const posibleSections = [
  'Company logo',
  'Candidate name',
  'Job title',
  'Soft skills',
  'Hard skills',
  'Education',
  'Languages',
  'Work experience',
  'Years of working experience',
  'Salary expectations',
  'Certificates',
  'Projects',
];

const CVTemplateAndSections: FC<StepsProps> = ({
  onChangeTemplateRequestData,
  stepsDefaultValues,
  isLoadingBlobsFromUrl,
}) => {
  const [mousePosition, setMousePosition] = useState({ x: 0, y: 0 });
  const { templateFiles, sections, extraSections } = stepsDefaultValues ?? {};
  const {
    control,
    handleSubmit,
    watch,
    setValue,
    register,
    formState: { errors },
  } = useForm<InferType<typeof schema>>({
    defaultValues: {
      templateFiles,
      sections: [
        ...(sections ?? []).map((section) => ({
          value: section,
          isEnable: true,
        })),
        ...posibleSections
          .filter(
            (posibleSingleSection) => !sections?.includes(posibleSingleSection)
          )
          .map((disabledSection) => ({
            value: disabledSection,
            isEnable: false,
          })),
      ],
      extraSections,
    },
    resolver: yupResolver(schema),
  });

  const handleOnDragEnd = (args: DropResult) => {
    const { source, destination } = args;
    if (!destination) return;
    const reorderedArr = [...watch('sections')];
    const [movedItem] = reorderedArr.splice(source.index, 1);
    reorderedArr.splice(destination.index, 0, movedItem);
    setValue('sections', reorderedArr);
  };

  const handleCheckboxChange = ({
    value,
    isEnable,
  }: {
    value: string;
    isEnable: boolean;
  }) => {
    const currentSections = watch('sections');
    const updatedSections = currentSections.map((section) =>
      value === section.value ? { ...section, isEnable: !isEnable } : section
    );
    setValue('sections', updatedSections);
  };

  return (
    <form
      onMouseMove={(e) => setMousePosition({ x: e.clientX, y: e.clientY })}
      className="flex w-full flex-col items-start gap-6"
      onSubmit={handleSubmit(async (formData) => {
        onChangeTemplateRequestData({
          ...formData,
          sections: formData.sections
            .filter((section) => section.isEnable)
            .map((section) => section.value),
        });
      })}
    >
      <div className="flex w-full flex-col gap-8">
        <div className="flex flex-col gap-2">
          <Text variant="h2" className="font-semibold text-neutral-1000">
            Custom Template
          </Text>
          <Text variant="body" className="flex flex-col text-neutral-1000">
            <span>
              Fill the form to let us create a custom Template for Your company.
            </span>
            <span>We will contact you to set all the details.</span>
          </Text>
        </div>
        <div className="flex flex-col gap-2">
          <Text variant="h4" className="font-semibold text-neutral-1000">
            Send Files
          </Text>
          <Text variant="body" className="text-neutral-1000">
            Upload existing template files in all the formats you have
          </Text>
        </div>
        <Controller
          control={control}
          name="templateFiles"
          render={({ field: { name, onChange, value } }) => (
            <FileInput
              variant="transparent"
              label="Company template"
              labelClassName="text-neutral-800 !text-caption !leading-4"
              accept={DocumentsFileTypes}
              onChange={onChange}
              value={value?.length === 0 ? undefined : value}
              name={name}
              multiple
              isLoading={isLoadingBlobsFromUrl?.isLoadingTemplateFiles}
              error={errors.templateFiles?.message}
            />
          )}
        />
        <div className="flex flex-col gap-2">
          <Text variant="h4" className="font-semibold text-neutral-1000">
            Resume sections
          </Text>
          <Text variant="body" className="text-neutral-1000">
            Choose the information you want to include in your resume template
            and arrange it in the appropriate order.
          </Text>
        </div>
        <div className="flex flex-col gap-3">
          <Label className="text-neutral-1000">Sections</Label>
          <DragDropContext
            autoScrollerOptions={{ disabled: true }}
            onDragEnd={handleOnDragEnd}
          >
            <Droppable droppableId="sectionDroppale">
              {(provided) => (
                <div
                  {...provided.droppableProps}
                  ref={provided.innerRef}
                  className="flex flex-col gap-2"
                >
                  {watch('sections').map((draggableSection, index) => (
                    <Draggable
                      key={draggableSection.value[0] + index.toString()}
                      index={index}
                      draggableId={JSON.stringify(draggableSection.value)}
                    >
                      {(draggableProvided) => (
                        <div
                          {...draggableProvided.draggableProps}
                          ref={draggableProvided.innerRef}
                          className="rounded-lg bg-neutral-200 px-3 py-2"
                          style={{
                            ...draggableProvided.draggableProps.style,
                            position: 'relative',
                            top: `${mousePosition.y} !important`,
                            left: `${mousePosition.x} !important`,
                          }}
                        >
                          <Checkbox
                            name={draggableSection.value}
                            key={draggableSection.value}
                            checked={draggableSection?.isEnable}
                            onChange={() =>
                              handleCheckboxChange(draggableSection)
                            }
                            className="!p-0"
                          >
                            <div className="flex flex-row items-center gap-3 self-center ">
                              <div {...draggableProvided.dragHandleProps}>
                                <FontAwesomeIcon
                                  icon={faGripVertical}
                                  className="text-neutral-1000"
                                />
                              </div>
                              <Text className="text-neutral-1000">
                                {draggableSection.value}
                              </Text>
                            </div>
                          </Checkbox>
                        </div>
                      )}
                    </Draggable>
                  ))}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </DragDropContext>
        </div>
        <MultiLineInput
          labelClassName="text-neutral-1000"
          label="Extra Sections"
          rows={3}
          variant="transparentNeutralBorder"
          {...register('extraSections')}
        />
      </div>
      <Button className="w-full" type="submit">
        Next
      </Button>
    </form>
  );
};
const schema = object({
  templateFiles: array().of(mixed<File | Blob>().required()),
  sections: array()
    .of(object({ value: string().defined(), isEnable: boolean().defined() }))
    .defined(),
  extraSections: string().defined(),
});

export default CVTemplateAndSections;
