import {
  faEllipsisVertical,
  faInfoCircle,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  CircularLoadingIndicator,
  Popover,
  PopoverTrigger,
  Tooltip,
  TooltipProvider,
  TooltipTrigger,
} from '@mindpal-co/mindpal-ui';
import { useQuery } from '@tanstack/react-query';
import { motion } from 'framer-motion';
import type { FC } from 'react';
import { useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import type { InferType } from 'yup';
import { mixed, object, string } from 'yup';

import { ImageFileTypes } from '@/config/filesTypes';
import { useBlobFromUrl } from '@/hooks/useBlobFromUrl';
import { useDeleteTemplateRequest } from '@/hooks/useDeleteTemplateRequest';
import { useProfile } from '@/hooks/useProfile';
import { useSettings } from '@/hooks/useSettings';
import { queries } from '@/queries';
import { Themes, themesOptions } from '@/services/cvGenerator';
import type { Settings } from '@/services/settings';
import { dateFormatter } from '@/utils/date';
import { uploadFileToFirebase } from '@/utils/firebase';

import Button from '../Button';
import FileInput from '../Form/Fields/FileInput';
import { Select } from '../Select';
import Text from '../Text';
import { PopoverContent } from '../ui/popover';
import { TooltipContent } from '../ui/tooltip';
import AddTemplateRequestDialog from './TemplateRequest/AddTemplateRequestDialog';
import EditTemplateRequestDialog from './TemplateRequest/EditTemplateRequestDialog';

type Props = {
  data?: Settings;
};
const Template: FC<Props> = ({ data }) => {
  const { mutate } = useSettings();
  const { data: profileData } = useProfile();
  const { data: dataLogoBlob, isLoading } = useBlobFromUrl(
    data?.logo ?? undefined
  );
  const { data: templateRequestsData, isLoading: templateRequestsIsLoading } =
    useQuery({
      ...queries.settings.templateRequest._ctx.list,
      refetchOnMount: false,
      refetchOnReconnect: false,
      refetchOnWindowFocus: false,
    });
  const { mutate: deleteTemplateRequest } = useDeleteTemplateRequest();
  const { control, watch, setValue } = useForm<InferType<typeof schema>>({
    defaultValues: {
      logo: data?.logo ? dataLogoBlob : null,
      defaultTemplate: data?.defaultTemplate ?? Themes.LIGHT,
    },
  });

  const saveLogo = async () => {
    if (!watch('logo')) return mutate({ logo: '' });
    const logo = await uploadFileToFirebase(watch('logo')!);
    mutate({ logo });
  };

  const defaultTemplateOptions = themesOptions
    .filter(
      ({ domain }) =>
        process.env.NODE_ENV === 'development' ||
        profileData?.accountType === 'ADMIN' ||
        (profileData?.email.includes(domain) ?? true)
    )
    .map(({ label, value }) => ({ label, value }));
  useEffect(() => {
    if (watch('logo')?.size !== dataLogoBlob?.size) {
      saveLogo();
    }
    if (
      JSON.stringify(data?.defaultTemplate) !==
      JSON.stringify(watch('defaultTemplate'))
    ) {
      mutate({ defaultTemplate: watch('defaultTemplate') });
    }
  }, [JSON.stringify(watch())]);

  useEffect(() => {
    if (!dataLogoBlob) return;
    setValue('logo', dataLogoBlob);
  }, [dataLogoBlob]);
  const logoType = watch('logo')?.type.split('/')[1];

  return (
    <form className="flex flex-row items-start gap-6">
      <div className="w-full max-w-xs">
        <Controller
          control={control}
          name="logo"
          render={({ field: { name, onChange, value } }) => (
            <FileInput
              clearable
              variant="transparent"
              label="Company logo"
              labelClassName="text-neutral-800 !text-caption !leading-4"
              accept={ImageFileTypes}
              onChange={onChange}
              value={value ?? undefined}
              name={name}
              defaultFileName={
                isLoading ? 'Loading...' : `YourLogo.${logoType}`
              }
            />
          )}
        />
      </div>
      <div className="flex flex-col gap-2">
        <Controller
          control={control}
          name="defaultTemplate"
          render={({ field: { name, onChange, value, onBlur } }) => (
            <Select
              name={name}
              label="Resume template"
              className="max-w-[300px]"
              labelClassName="!p-0"
              variant="light"
              sizeVariant="sm"
              onChange={(newValue) => onChange(newValue?.value)}
              value={defaultTemplateOptions.find(
                (option) => option.value === value
              )}
              onBlur={onBlur}
              options={defaultTemplateOptions}
            />
          )}
        />

        <TooltipProvider>
          <Tooltip delayDuration={0}>
            <TooltipTrigger className="flex flex-row items-center justify-center gap-2">
              <Text variant="body-caption" className="text-neutral-800">
                Request customized resume template
              </Text>
              <FontAwesomeIcon
                size="xs"
                className="text-neutral-800"
                icon={faInfoCircle}
              />
            </TooltipTrigger>
            <TooltipContent className="w-60">
              <Text className="text-neutral-1000" variant="body-caption">
                Need to keep Your company’s style? Move your current template to
                MindPal or let us create one for you.
              </Text>
            </TooltipContent>
          </Tooltip>
        </TooltipProvider>
        {templateRequestsIsLoading && (
          <motion.div
            initial={{ height: 'auto', opacity: 1 }}
            exit={{ height: 'auto', opacity: 1 }}
            className="flex flex-row items-center justify-center gap-2"
          >
            <Text className="text-neutral-1000">Loading</Text>
            <CircularLoadingIndicator size="sm" />
          </motion.div>
        )}
        {!!templateRequestsData && (
          <motion.div
            className="flex flex-col gap-3"
            initial={{ height: 0, opacity: 0 }}
            animate={{ height: 'auto', opacity: 1 }}
          >
            <AddTemplateRequestDialog dialogTriggerProps={{ asChild: true }}>
              <Button>
                {templateRequestsData?.results.length === 0
                  ? 'Fill form'
                  : 'New request'}
              </Button>
            </AddTemplateRequestDialog>
            {templateRequestsData?.results.map((singleTemplate) => (
              <div
                key={singleTemplate.id}
                className="flex flex-row items-center justify-between gap-2 text-neutral-1000"
              >
                <div className="flex flex-row items-center gap-2">
                  <Text variant="body-caption">
                    {dateFormatter({
                      date: singleTemplate.createdAt,
                      format: { dateStyle: 'short' },
                    })}
                  </Text>
                  <Text variant="body-caption">
                    {singleTemplate.companyName}
                  </Text>
                </div>
                <Popover modal>
                  <PopoverTrigger>
                    <FontAwesomeIcon icon={faEllipsisVertical} />
                  </PopoverTrigger>
                  <PopoverContent className="flex w-40 flex-col gap-2">
                    <EditTemplateRequestDialog
                      data={singleTemplate}
                      dialogTriggerProps={{ asChild: true }}
                    >
                      <Button
                        variant="unstyled"
                        size={null}
                        className="w-full !justify-start"
                      >
                        Edit
                      </Button>
                    </EditTemplateRequestDialog>
                    <Button
                      variant="unstyled"
                      size={null}
                      className="w-full !justify-start"
                      confirm="Are you sure you want to delete this request template? This cannot be undone"
                      onClick={() => deleteTemplateRequest(singleTemplate.id)}
                    >
                      Delete
                    </Button>
                  </PopoverContent>
                </Popover>
              </div>
            ))}
          </motion.div>
        )}
      </div>
    </form>
  );
};

export default Template;

const schema = object({
  defaultTemplate: string().defined(),
  logo: mixed<File | Blob>().nullable().defined(),
});
