import {
  Popover,
  PopoverContent as PopoverContentBase,
  PopoverTrigger,
} from '@mindpal-co/mindpal-ui';
import type { VariantProps } from 'class-variance-authority';
import { cva } from 'class-variance-authority';
import type { ComponentPropsWithoutRef, ElementRef } from 'react';
import { forwardRef } from 'react';

import { cn } from '@/lib/utils';

export const popoverStyles = cva('', {
  variants: {
    variant: {
      primary: 'border-slate-800 bg-slate-950 text-slate-50',
      'jb-primary': 'rounded-lg bg-neutral-200 text-neutral-1000',
    },
  },
  defaultVariants: {
    variant: 'primary',
  },
});

const PopoverContent = forwardRef<
  ElementRef<typeof PopoverContentBase>,
  ComponentPropsWithoutRef<typeof PopoverContentBase> &
    VariantProps<typeof popoverStyles>
>(({ className, variant, align = 'center', sideOffset = 4, ...props }, ref) => (
  <PopoverContentBase
    ref={ref}
    align={align}
    sideOffset={sideOffset}
    className={cn(popoverStyles({ variant }), className)}
    {...props}
  />
));

PopoverContent.displayName = PopoverContentBase.displayName;

export { Popover, PopoverContent, PopoverTrigger };
