export const parseEnumToOptions = (
  posibleValues: Record<string, string> | null
) => {
  if (!posibleValues) return [];
  return Object.values(posibleValues).map((value) => ({
    value,
    label: `${value[0]}${value
      .substring(1)
      .toLocaleLowerCase()
      .replaceAll('_', ' ')}`,
  }));
};
