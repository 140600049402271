import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';
import type { Step } from 'react-joyride';

import { isDevelopment } from '@/config/config';

import { useProfile } from '../useProfile';

export const useSettingsOnboarding = () => {
  const { data: profileData } = useProfile();
  const router = useRouter();
  const onboardingState = useState<{
    run: boolean;
    steps: (Step & { onNext?: () => void })[];
  }>({
    run: false,
    steps: [
      {
        target: '[data-joyride="settings-dialog"]',
        title: 'Settings',
        content: 'Customize resume files to your preferences with one click. ',
        disableBeacon: true,
        locale: {
          next: 'Start tutorial',
        },
        placement: 'center',
      },
      {
        target: '[data-joyride="settings-dialog"]',
        title: 'Date settings',
        content:
          'Customize the global date format for candidates resume tamplates.',
        disableBeacon: true,
        data: {
          href: { query: { ...router.query, setting: 'Work experience' } },
          prevHref: { query: { ...router.query, setting: 'Date' } },
        },
        locale: {
          next: "Let's get started",
        },
      },
      {
        target: '[data-joyride="settings-dialog"]',
        title: 'Work experience',
        content: 'Adjust the displayed format of work experience.',
        disableBeacon: true,
        data: {
          href: { query: { ...router.query, setting: 'Language' } },
          prevHref: { query: { ...router.query, setting: 'Date' } },
        },
      },
      {
        target: '[data-joyride="settings-dialog"]',
        title: 'Language',
        content:
          'Choose the output of the resume as well as displayed format of the proficiency level.',
        disableBeacon: true,
        data: {
          href: { query: { ...router.query, setting: 'Template' } },
          prevHref: { query: { ...router.query, setting: 'Work experience' } },
        },
      },
      {
        target: '[data-joyride="settings-dialog"]',
        title: 'Template',
        content:
          'Upload your custom template file or use the one that we prepared for you.',
        disableBeacon: true,
        data: {
          href: { query: { ...router.query, setting: 'Date' } },
          prevHref: { query: { ...router.query, setting: 'Language' } },
        },
      },
    ],
  });

  useEffect(() => {
    if (!profileData || isDevelopment) return;
    if (!profileData.settingsOnboardingAccomplished)
      onboardingState[1]((prev) => ({ ...prev, run: true }));
  }, [profileData?.settingsOnboardingAccomplished]);

  return onboardingState;
};
