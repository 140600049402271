export const dateFormatter = ({
  date,
  format = { month: 'short', year: 'numeric' },
  locales,
}: {
  date: Date;
  format?: Intl.DateTimeFormatOptions;
  locales?: string | string[];
}) => {
  return Intl.DateTimeFormat(locales, format).format(date);
};

export const timeAgo = (date: Date) => {
  const seconds = Math.floor((new Date().valueOf() - date.valueOf()) / 1000);

  let interval = seconds / 31536000;

  if (interval > 1) {
    return `${Math.floor(interval)} years ago`;
  }
  interval = seconds / 2592000;
  if (interval > 1) {
    return `${Math.floor(interval)} mth ago`;
  }
  interval = seconds / 86400;
  if (interval > 1) {
    return `${Math.floor(interval)} days ago`;
  }
  interval = seconds / 3600;
  if (interval > 1) {
    return `${Math.floor(interval)}h ago`;
  }
  interval = seconds / 60;
  if (interval > 1) {
    return `${Math.floor(interval)}m ago`;
  }
  return `${Math.floor(seconds)}s ago`;
};
