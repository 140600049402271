import type { FC } from 'react';

import type { SendToAPIRequestTemplate } from '@/services/settings';

import Contact from './Steps/Contact';
import CVTemplateAndSections from './Steps/CVTemplateAndSections';
import LogoFontsTypography from './Steps/LogoFontsTypography';

export type StepsProps = {
  onChangeTemplateRequestData: (
    values: Partial<SendToAPIRequestTemplate>
  ) => void;
  onPrevStep?: (values?: Partial<SendToAPIRequestTemplate>) => void;
  stepsDefaultValues?: SendToAPIRequestTemplate;
  isLoadingBlobsFromUrl?: {
    isLoadingCustomFontFile?: boolean;
    isLoadingLogoFile?: boolean;
    isLoadingTemplateFiles?: boolean;
  };
};

type Props = {
  step: number;
} & StepsProps;
const LayoutTemplateRequest: FC<Props> = ({
  step,
  onChangeTemplateRequestData,
  stepsDefaultValues,
  onPrevStep,
  isLoadingBlobsFromUrl,
}) => {
  const steps = [
    <CVTemplateAndSections
      key={1}
      isLoadingBlobsFromUrl={isLoadingBlobsFromUrl}
      stepsDefaultValues={stepsDefaultValues}
      onChangeTemplateRequestData={onChangeTemplateRequestData}
    />,
    <LogoFontsTypography
      key={2}
      isLoadingBlobsFromUrl={isLoadingBlobsFromUrl}
      stepsDefaultValues={stepsDefaultValues}
      onChangeTemplateRequestData={onChangeTemplateRequestData}
      onPrevStep={onPrevStep}
    />,
    <Contact
      key={3}
      stepsDefaultValues={stepsDefaultValues}
      onChangeTemplateRequestData={onChangeTemplateRequestData}
      onPrevStep={onPrevStep}
    />,
  ];
  return (
    <div className="w-full">
      <div className="flex w-full max-w-3xl flex-col items-center gap-5">
        {steps[step - 1]}
      </div>
    </div>
  );
};

export default LayoutTemplateRequest;
