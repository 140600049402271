import { type FC, useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import type { InferType } from 'yup';
import { object, string } from 'yup';

import { useSettings } from '@/hooks/useSettings';
import { DateFormatOptions, type Settings } from '@/services/settings';

import { Select } from '../Select';

type Props = {
  data?: Settings;
};

const Date: FC<Props> = ({ data }) => {
  const { mutate } = useSettings();
  const { control, watch } = useForm<InferType<typeof schema>>({
    defaultValues: {
      dateFormat: data?.dateFormat ?? null,
    },
  });

  useEffect(() => {
    if (
      JSON.stringify(data?.dateFormat) !== JSON.stringify(watch('dateFormat'))
    ) {
      mutate(watch());
    }
  }, [JSON.stringify(watch())]);

  const options = [
    {
      value: DateFormatOptions.MONTH_YEAR,
      label: '01-2024',
    },
    {
      value: DateFormatOptions.MONTH_YEAR_DOT,
      label: '01.2024',
    },
    {
      value: DateFormatOptions.YEAR,
      label: '2024',
    },
    {
      value: DateFormatOptions.MONTH_NAME_YEAR,
      label: 'January 2024',
    },
    {
      value: DateFormatOptions.MONTH_SHORT_NAME_YEAR,
      label: 'Jan. 2024',
    },
    {
      value: DateFormatOptions.YEAR_MONTH,
      label: '2024-01',
    },
    {
      value: DateFormatOptions.YEAR_MONTH_DOT,
      label: '2024.01',
    },
    { value: DateFormatOptions.SOURCE, label: 'Format used in original CV' },
  ];
  return (
    <form>
      <Controller
        control={control}
        name="dateFormat"
        render={({ field: { name, onChange, value, onBlur } }) => (
          <Select
            isSearchable={false}
            name={name}
            label="Date format"
            className="max-w-[400px]"
            variant="light"
            sizeVariant="sm"
            onChange={(newValue) => onChange(newValue?.value)}
            value={options.find((option) => option.value === value)}
            onBlur={onBlur}
            options={options}
          />
        )}
      />
    </form>
  );
};

export default Date;

const schema = object({
  dateFormat: string()
    .nullable()
    .defined()
    .oneOf([...Object.values(DateFormatOptions), null, '']),
});
