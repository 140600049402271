import {
  type DialogContentProps,
  type DialogProps,
  type DialogTriggerProps,
} from '@radix-ui/react-dialog';
import type { FC, PropsWithChildren } from 'react';
import ReactGA from 'react-ga4';

import { GAActions, GACategories } from '@/config/googleAnalytics';

import { Dialog, DialogContent, DialogTrigger } from '../ui/dialog';
import ChangelogLayout from './ChangelogLayout';

type Props = {
  dialogTriggerProps?: DialogTriggerProps;
  dialogContentProps?: DialogContentProps;
  dialogProps?: DialogProps;
};
const ChangelogDialog: FC<PropsWithChildren<Props>> = ({
  dialogTriggerProps,
  dialogContentProps,
  dialogProps,
  children,
}) => {
  return (
    <Dialog
      {...dialogProps}
      onOpenChange={(open) => {
        if (open)
          ReactGA.event({
            category: GACategories.CHANGELOG,
            action: GAActions.OPEN_CHANGELOG,
          });
        dialogProps?.onOpenChange?.(open);
      }}
    >
      <DialogTrigger {...dialogTriggerProps}>{children}</DialogTrigger>
      <DialogContent
        closeButtonClassName="bg-neutral-200 p-1 !rounded-full"
        className="aspect-[5/3] w-full !bg-transparent p-0 md:max-w-6xl"
        {...dialogContentProps}
      >
        <ChangelogLayout />
      </DialogContent>
    </Dialog>
  );
};

export default ChangelogDialog;
