import { Icon } from '@iconify/react';
import dynamic from 'next/dynamic';
import { useRouter } from 'next/router';
import { type ComponentProps, useEffect, useRef, useState } from 'react';
import type {
  CallBackProps,
  Step,
  StoreHelpers,
  TooltipRenderProps,
} from 'react-joyride';
import { ACTIONS, EVENTS, STATUS } from 'react-joyride';

import Button from './Button';
import Text from './Text';

const JoyrideBase = dynamic(() => import('react-joyride'), { ssr: false });

const Joyride = ({
  onFinished,
  skipOnFinished = false,
  ...props
}: ComponentProps<typeof JoyrideBase> & {
  onFinished?: () => void;
  skipOnFinished?: boolean;
}) => {
  const helpers = useRef<StoreHelpers>();

  const [stepIndex, setStepIndex] = useState(0);
  const router = useRouter();
  const getStepElement = (target: string | HTMLElement) => {
    return typeof target === 'string' ? document.querySelector(target) : target;
  };

  const handleSpotlightClick = (event: MouseEvent) => {
    const currentStep = props.steps[stepIndex];
    const isLastStep = stepIndex === props.steps.length - 1;
    if (currentStep.spotlightClicks) {
      let target = event.target as HTMLElement;

      const stepTarget = getStepElement(currentStep.target) as HTMLElement;

      while (
        target &&
        target?.dataset?.joyride !== stepTarget?.dataset?.joyride
      ) {
        target = target.parentElement as HTMLElement;
      }
      if (target?.dataset?.joyride === stepTarget?.dataset?.joyride) {
        if (!isLastStep) {
          setTimeout(() => {
            setStepIndex(stepIndex + 1);
          }, 250);
        }
      }
    }
  };

  const handleJoyrideCallback = (data: CallBackProps) => {
    const { action, index, status, type, step } = data;
    if (action === ACTIONS.CLOSE) {
      onFinished?.();
    }
    if (([STATUS.FINISHED, STATUS.SKIPPED] as string[]).includes(status)) {
      setStepIndex(0);
      if (status === STATUS.FINISHED && skipOnFinished) return;
      onFinished?.();
    } else if (
      ([EVENTS.STEP_AFTER, EVENTS.TARGET_NOT_FOUND] as string[]).includes(type)
    ) {
      const nextStepIndex = index + (action === ACTIONS.PREV ? -1 : 1);
      if (step.data?.prevHref && action === ACTIONS.PREV)
        router.replace(step.data.prevHref);
      else if (step.data?.href) router.replace(step.data.href);
      if (step.data?.delay && typeof step.data.delay === 'number')
        return setTimeout(() => setStepIndex(nextStepIndex), step.data.delay);
      setStepIndex(nextStepIndex);
    }
  };

  useEffect(() => {
    document.addEventListener('click', handleSpotlightClick);
    return () => {
      document.removeEventListener('click', handleSpotlightClick);
    };
  }, [stepIndex]);
  return (
    <JoyrideBase
      continuous
      tooltipComponent={(tooltipProps) => (
        <CustomTooltip
          {...tooltipProps}
          stepIndex={stepIndex}
          steps={props.steps}
        />
      )}
      {...props}
      getHelpers={(joyrideHelpers) => {
        helpers.current = joyrideHelpers;
        props.getHelpers?.(joyrideHelpers);
      }}
      callback={handleJoyrideCallback}
      stepIndex={stepIndex}
      locale={{ last: 'Finish tutorial' }}
      disableCloseOnEsc
      disableOverlayClose
      spotlightPadding={0}
      showProgress
      floaterProps={{
        styles: {
          arrow: {
            margin: '32px',
          },
        },
      }}
      styles={{
        options: {
          backgroundColor: '#fff',
          arrowColor: '#ffffff',
        },
        spotlight: {
          borderRadius: '0.5rem',
        },
      }}
    />
  );
};

export default Joyride;

const CustomTooltip = (
  props: TooltipRenderProps & { steps: Step[]; stepIndex: number }
) => {
  const {
    backProps,
    closeProps,
    continuous,
    index,
    primaryProps,
    skipProps,
    step,
    tooltipProps,
    stepIndex,
    steps,
  } = props;

  return (
    <div
      className="tooltip__body relative max-w-sm rounded-lg bg-white p-5 text-black"
      {...tooltipProps}
    >
      <button
        type="button"
        className="tooltip__close absolute right-5 top-5"
        {...closeProps}
      >
        <Icon icon="material-symbols:close" className="text-2xl" />
      </button>
      {step.title && (
        <Text as="h4" className="tooltip__title font-semibold" variant="body">
          {step.title}
        </Text>
      )}
      <div className="tooltip__content my-3">
        <Text variant="body-small" className="text-neutral-800">
          {step.content}
        </Text>
      </div>
      {!step.hideFooter && (
        <div className="tooltip__footer mt-1 flex items-end justify-between gap-2">
          {step.showSkipButton && (
            <button type="button" className="tooltip__button" {...skipProps}>
              {skipProps.title}
            </button>
          )}
          <div className="tooltip__spacer flex gap-2">
            {index > 0 && !step.hideBackButton && (
              <Button
                variant="quaternary"
                type="button"
                className="tooltip__button px-8 py-3  text-caption text-[##3755E0]"
                {...backProps}
              >
                {backProps.title}
              </Button>
            )}
            {continuous && (
              <Button
                type="button"
                className="tooltip__button tooltip__button--primary px-8 py-3 text-caption text-white focus-within:before:bottom-0 focus-within:before:translate-y-[120%]"
                {...primaryProps}
              >
                {primaryProps.title}
              </Button>
            )}
          </div>
          {step.showProgress && step.placement !== 'center' && (
            <div>
              <Text variant="body-caption">
                {stepIndex + (steps?.[0]?.placement === 'center' ? 0 : 1)}/
                {steps.length - (steps?.[0]?.placement === 'center' ? 1 : 0)}
              </Text>
            </div>
          )}
        </div>
      )}
    </div>
  );
};
