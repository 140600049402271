import { Icon } from '@iconify/react';
import { useQuery } from '@tanstack/react-query';
import classNames from 'classnames';
import type { FC } from 'react';

import ChangelogDialog from '@/components/Changelog/ChangelogDialog';
import Text from '@/components/Text';
import { queries } from '@/queries';

type Props = {
  navigationExpanded: boolean;
};
const ChangelogButton: FC<Props> = ({ navigationExpanded }) => {
  const { data: changelogData } = useQuery({
    ...queries.changelog.list,
    staleTime: 10 * 60 * 1000,
  });
  const isUnreadChangelog = changelogData?.results.some(
    ({ userSeen }) => !userSeen
  );
  const lengthOfUnreadChangelog = changelogData?.results.filter(
    ({ userSeen }) => !userSeen
  ).length;
  return (
    <ChangelogDialog dialogTriggerProps={{ asChild: true }}>
      <div className="border-b border-primary-200 py-1">
        <div className="relative flex h-min rounded-lg p-3 transition-colors hover:bg-primary-700">
          <Icon
            icon="material-symbols-light:notifications-outline"
            className="text-2xl"
          />
          <Text
            className={classNames(
              'w-0 transition-all overflow-hidden whitespace-nowrap',
              {
                'w-32 ml-3': navigationExpanded,
              }
            )}
          >
            What’s new?
          </Text>
          {isUnreadChangelog && navigationExpanded && (
            <Text
              variant="body-caption"
              className="absolute right-0 flex aspect-square h-6 items-center justify-center rounded-full bg-error"
            >
              {lengthOfUnreadChangelog}
            </Text>
          )}
          {isUnreadChangelog && !navigationExpanded && (
            <div className="absolute right-3 top-4 aspect-square w-3 -translate-y-1/2 rounded-full bg-primary-800">
              <div className="absolute left-1/2 top-1/2 aspect-square w-2 -translate-x-1/2 -translate-y-1/2 animate-pulse rounded-full bg-error text-center" />
            </div>
          )}
        </div>
      </div>
    </ChangelogDialog>
  );
};

export default ChangelogButton;
