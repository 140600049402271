import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import { useRouter } from 'next/router';
import type { ComponentPropsWithoutRef, FC, PropsWithChildren } from 'react';

import Button from './Button';

type Props = {
  onClick?: () => void;
  theme?: 'dark' | 'light';
} & ComponentPropsWithoutRef<'button'>;

const BackButton: FC<PropsWithChildren<Props>> = ({
  onClick,
  children,
  theme = 'dark',
  ...props
}) => {
  const router = useRouter();
  return (
    <Button
      {...props}
      onClick={() => (onClick ? onClick?.() : router.back())}
      variant="unstyled"
      size={null}
    >
      <div className="flex items-center justify-center gap-2">
        <FontAwesomeIcon
          icon={faArrowLeft}
          className={classNames({ 'text-neutral-900': theme === 'light' })}
        />
        {children}
      </div>
    </Button>
  );
};

export default BackButton;
