import { yupResolver } from '@hookform/resolvers/yup';
import { type FC } from 'react';
import { Controller, useForm } from 'react-hook-form';
import type { InferType } from 'yup';
import { object, string } from 'yup';

import Button from '@/components/Button';
import MultiLineInput from '@/components/Form/Fields/MultiLineInput';
import PhoneInputField from '@/components/Form/Fields/PhoneInputField';
import TextInputField from '@/components/Form/Fields/TextInputField';

import type { StepsProps } from '../LayoutTemplateRequest';

const Contact: FC<StepsProps> = ({
  onChangeTemplateRequestData,
  stepsDefaultValues,
  onPrevStep,
}) => {
  const { companyName, email, phone, notes } = stepsDefaultValues ?? {};
  const {
    control,
    handleSubmit,
    register,
    formState: { errors },
    watch,
  } = useForm<InferType<typeof schema>>({
    defaultValues: { companyName, email, phone, notes },
    resolver: yupResolver(schema),
  });

  return (
    <form
      className="flex w-full flex-col gap-6"
      onSubmit={handleSubmit((formData) => {
        onChangeTemplateRequestData(formData);
      })}
    >
      <div className="flex w-full flex-col gap-6 text-neutral-1000">
        <TextInputField
          {...register('companyName')}
          label="Company name"
          variant="transparent"
          containerClassName="!gap-2"
          placeholder="Name"
          labelClassName="text-neutral-1000"
          error={errors.companyName?.message}
        />
        <TextInputField
          {...register('email')}
          label="E-mail"
          variant="transparent"
          containerClassName="!gap-2"
          placeholder="johndoe@gmail.com"
          labelClassName="text-neutral-1000"
          error={errors.email?.message}
        />
        <Controller
          control={control}
          name="phone"
          render={({ field }) => (
            <PhoneInputField
              labelClassName="text-neutral-800"
              label="Phone number"
              variant="transparent"
              error={errors.phone}
              {...field}
            />
          )}
        />
        <MultiLineInput
          {...register('notes')}
          label="Notes"
          variant="transparent"
          placeholder="Your suggestions"
          labelClassName="text-neutral-1000"
        />
      </div>
      <div className="flex flex-row gap-2">
        <Button
          variant="secondary"
          onClick={() => onPrevStep?.(watch())}
          className="w-full"
        >
          Back
        </Button>
        <Button type="submit" className="w-full">
          Send request
        </Button>
      </div>
    </form>
  );
};
const schema = object({
  companyName: string().required('Company name is required'),
  email: string()
    .email('Enter a valid email address')
    .required('E-mail is required'),
  phone: string().required('Phone number is required'),
  notes: string(),
});

export default Contact;
