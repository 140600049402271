import {
  faArrowLeftLong,
  faArrowRightLong,
} from '@fortawesome/free-solid-svg-icons';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import classNames from 'classnames';
import { type Dispatch, type FC, type SetStateAction, useEffect } from 'react';

import Logo from '@/assets/logo-mindpal.svg';
import { queries } from '@/queries';
import { type Changelog, postChangelogSeen } from '@/services/changelog';
import type { DjangoData } from '@/services/common';
import { preloadImg } from '@/utils/preloadImg';

import Button from '../Button';
import Text from '../Text';

type Props = {
  setCurrentChangelog: Dispatch<SetStateAction<number | null>>;
  currentChangelog: number | null;
  data?: DjangoData<Changelog>;
  className?: string;
};

const ChangelogNavigation: FC<Props> = ({
  setCurrentChangelog,
  currentChangelog,
  data,
  className,
}) => {
  const queryClient = useQueryClient();
  const prefetchSingleChangeLog = async (id: number) => {
    await queryClient.prefetchQuery({
      ...queries.changelog.detail(id),
      staleTime: 1000 * 60,
    });
    const queryData = queryClient.getQueryData<Changelog>(
      queries.changelog.detail(id).queryKey
    );
    preloadImg(queryData?.img);
  };

  const { mutate } = useMutation({
    mutationFn: postChangelogSeen,
    mutationKey: ['changelog', 'seen'],
    onSuccess: () => {
      queryClient.invalidateQueries(queries.changelog.list);
    },
  });

  useEffect(() => {
    if (currentChangelog) {
      mutate(currentChangelog);
    }
  }, [currentChangelog]);

  useEffect(() => {
    if (data && data?.results.length > 0) {
      setCurrentChangelog(data?.results[0].id);
    }
  }, []);

  const dataLength = data?.results.length;
  const isFirst =
    data?.results[0]?.id && data.results[0].id === currentChangelog;
  const isLast =
    dataLength &&
    (data?.results[dataLength - 1].id ?? null) === currentChangelog;

  return (
    <div className={classNames('flex flex-col bg-neutral-1000', className)}>
      <div className="flex h-full flex-col items-start justify-start gap-12 overflow-auto p-20">
        <div className="w-full">
          <Logo />
        </div>
        <div className="flex flex-col gap-5 overflow-auto">
          <Text variant="h5" className="font-semibold">
            What&apos;s new?
          </Text>
          <div className="scroll-transparent flex flex-col gap-2 overflow-auto">
            {data?.results.map(({ id, title, userSeen }) => (
              <Button
                className="w-fit"
                size="xs"
                variant="unstyled"
                key={id}
                onMouseEnter={() => prefetchSingleChangeLog(id)}
                onClick={() => setCurrentChangelog(id)}
              >
                <Text
                  className={classNames('font-ligh', {
                    '!font-bold': !userSeen,
                    'text-primary-300 !font-bold': currentChangelog === id,
                  })}
                >
                  {title}
                </Text>
              </Button>
            ))}
          </div>
        </div>
      </div>
      <div className="flex w-full flex-row gap-2 p-4">
        {!isFirst && currentChangelog && (
          <Button
            onClick={() => setCurrentChangelog(currentChangelog + 1)}
            onMouseEnter={() => prefetchSingleChangeLog(currentChangelog + 1)}
            variant="gray"
            className="flex flex-1"
            prefixIcon={faArrowLeftLong}
          >
            Back
          </Button>
        )}
        {!isLast && currentChangelog && (
          <Button
            onClick={() => setCurrentChangelog(currentChangelog - 1)}
            onMouseEnter={() => prefetchSingleChangeLog(currentChangelog - 1)}
            className="flex flex-1"
            suffixIcon={faArrowRightLong}
          >
            Next
          </Button>
        )}
      </div>
    </div>
  );
};

export default ChangelogNavigation;
