const loadedImages = new Set<string>();

export const preloadImg = (imageSrc?: string, clearTime?: number) => {
  if (!imageSrc || loadedImages.has(imageSrc)) return;
  const img = new Image();
  img.src = imageSrc;
  loadedImages.add(imageSrc);

  setTimeout(() => {
    loadedImages.delete(imageSrc);
  }, clearTime ?? 60 * 1000);
};
