import { type FC, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import type { InferType } from 'yup';
import { object, string } from 'yup';

import { useDebounce } from '@/hooks/useDebounce';
import { useSettings } from '@/hooks/useSettings';
import type { Settings } from '@/services/settings';

import TextInputField from '../Form/Fields/TextInputField';
import Text from '../Text';

type Props = {
  data?: Settings;
};

const CustomLanguageLevel: FC<Props> = ({ data }) => {
  const { mutate } = useSettings();
  const { register, watch } = useForm<InferType<typeof schema>>({
    defaultValues: data?.languageLevelCustom,
  });
  const debouncedWatch = useDebounce(watch());
  useEffect(() => {
    if (
      JSON.stringify(data?.languageLevelCustom) !==
      JSON.stringify(debouncedWatch)
    ) {
      mutate({ languageLevelCustom: debouncedWatch });
    }
  }, [JSON.stringify(debouncedWatch)]);

  const languagesLevels: [
    'basic',
    'intermediate',
    'advanced',
    'nearNative',
    'native'
  ] = ['basic', 'intermediate', 'advanced', 'nearNative', 'native'];

  return (
    <form className="flex w-full max-w-xl flex-row flex-wrap gap-4">
      {languagesLevels.map((level, index) => (
        <div key={level} className="flex flex-row items-center gap-2">
          <Text variant="body-small" className="text-neutral-1000">
            {index + 1} -
          </Text>
          <TextInputField
            variant="light"
            sizeVariant="xs"
            placeholder={level}
            {...register(level)}
          />
        </div>
      ))}
    </form>
  );
};

export default CustomLanguageLevel;

const schema = object({
  basic: string(),
  native: string(),
  advanced: string(),
  nearNative: string(),
  intermediate: string(),
});
