import { type FC } from 'react';
import { Controller, useForm } from 'react-hook-form';
import type { InferType } from 'yup';
import { mixed, object, string } from 'yup';

import Button from '@/components/Button';
import FileInput from '@/components/Form/Fields/FileInput';
import TextInputField from '@/components/Form/Fields/TextInputField';
import { Select } from '@/components/Select';
import Text from '@/components/Text';
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from '@/components/ui/accordion';
import { FontFileTypes, ImageFileTypes } from '@/config/filesTypes';
import fontsOptions from '@/data/fonts.json';

import type { StepsProps } from '../LayoutTemplateRequest';

const LogoFontsTypography: FC<StepsProps> = ({
  onChangeTemplateRequestData,
  stepsDefaultValues,
  onPrevStep,
  isLoadingBlobsFromUrl,
}) => {
  const {
    logoFile,
    customFontFile,
    fontName,
    title,
    header1,
    header2,
    text,
    caption,
  } = stepsDefaultValues ?? {};
  const { control, handleSubmit, register, watch } = useForm<
    InferType<typeof schema>
  >({
    defaultValues: {
      logoFile,
      customFontFile,
      fontName,
      title,
      header1,
      header2,
      text,
      caption,
    },
  });

  const options = fontsOptions.fonts.map((font) => ({
    value: font,
    label: font,
  }));

  const createButtonLabelString = (
    labelIfIsBlob: string,
    file: Blob | File
  ) => {
    const isFileType = 'name' in file;
    if (isFileType) {
      return file.name;
    }
    return labelIfIsBlob;
  };

  return (
    <form
      className="flex w-full flex-col gap-6"
      onSubmit={handleSubmit((formData) => {
        onChangeTemplateRequestData(formData);
      })}
    >
      <div className="flex w-full flex-col gap-6">
        <Controller
          control={control}
          name="logoFile"
          render={({ field: { name, onChange, value } }) => (
            <FileInput
              variant="gray"
              isButtonStyled
              label="Logo"
              buttonLabel={
                !value
                  ? 'Upload logo'
                  : createButtonLabelString('Change uploaded logo', value)
              }
              prefixIcon={value ? 'bi-cloud-check-fill' : undefined}
              labelClassName="text-neutral-1000"
              accept={ImageFileTypes}
              onChange={onChange}
              value={value ?? undefined}
              name={name}
              isLoading={!!value && isLoadingBlobsFromUrl?.isLoadingLogoFile}
            />
          )}
        />
        <div className="flex flex-col gap-2">
          <Controller
            control={control}
            name="fontName"
            render={({ field: { name, onChange, value } }) => (
              <Select
                options={options}
                className="overflow-visible"
                variant="transparent"
                isSearchable
                isClearable
                label="Font name"
                labelClassName="text-neutral-1000"
                onChange={(choosenValue) => onChange(choosenValue?.value)}
                value={options.find((option) => option.value === value)}
                name={name}
              />
            )}
          />
          <Controller
            control={control}
            name="customFontFile"
            render={({ field: { name, onChange, value } }) => (
              <FileInput
                variant="gray"
                isButtonStyled
                buttonLabel={
                  !value
                    ? 'Upload font'
                    : createButtonLabelString('Change uploaded font', value)
                }
                prefixIcon={value ? 'bi-cloud-check-fill' : undefined}
                accept={FontFileTypes}
                onChange={onChange}
                value={value ?? undefined}
                name={name}
                isLoading={
                  !!value && isLoadingBlobsFromUrl?.isLoadingCustomFontFile
                }
              />
            )}
          />
          <Accordion type="single" className="text-neutral-1000" collapsible>
            <AccordionItem value="advancedOptions" className="border-0">
              <AccordionTrigger>
                <Text variant="h4" className="font-semibold">
                  Advanced options
                </Text>
              </AccordionTrigger>
              <AccordionContent>
                <div className="flex flex-col gap-4">
                  <TextInputField
                    {...register('title')}
                    label="Title"
                    variant="transparent"
                    containerClassName="!gap-1"
                    placeholder="24px, Open Sans Bold, #7049D5"
                  />
                  <TextInputField
                    {...register('header1')}
                    label="Header 1"
                    variant="transparent"
                    containerClassName="!gap-1"
                    placeholder="20px, Open Sans Semi Bold, #000000"
                  />
                  <TextInputField
                    {...register('header2')}
                    label="Header 2"
                    variant="transparent"
                    containerClassName="!gap-1"
                    placeholder="18px, Open Sans Medium, #000000"
                  />
                  <TextInputField
                    {...register('text')}
                    label="Text"
                    variant="transparent"
                    containerClassName="!gap-1"
                    placeholder="16px, Open Sans Regular, #000000"
                  />
                  <TextInputField
                    {...register('caption')}
                    label="Caption"
                    variant="transparent"
                    containerClassName="!gap-1"
                    placeholder="12px, Open Sans Regular, #000000"
                  />
                </div>
              </AccordionContent>
            </AccordionItem>
          </Accordion>
        </div>
      </div>
      <div className="flex flex-row gap-2">
        <Button
          variant="secondary"
          onClick={() => onPrevStep?.(watch())}
          className="w-full"
        >
          Back
        </Button>
        <Button type="submit" className="w-full">
          Next
        </Button>
      </div>
    </form>
  );
};
const schema = object({
  logoFile: mixed<File | Blob>().nullable().defined(),
  customFontFile: mixed<File | Blob>().nullable().defined(),
  fontName: string(),
  title: string(),
  header1: string(),
  header2: string(),
  text: string(),
  caption: string(),
});

export default LogoFontsTypography;
