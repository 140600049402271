import { useEffect, useState } from 'react';

export function useDebounce<T>(value: T, delay?: number): T {
  const [debouncedValue, setDebouncedValue] = useState<T>(value);
  const stringifyValue = (val: unknown) => {
    try {
      return JSON.stringify(val);
    } catch (err) {
      return val;
    }
  };
  useEffect(() => {
    const timer = setTimeout(() => setDebouncedValue(value), delay || 500);

    return () => {
      clearTimeout(timer);
    };
  }, [stringifyValue(value), delay]);

  return debouncedValue;
}
