import {
  Tooltip,
  TooltipProvider,
  TooltipTrigger,
} from '@mindpal-co/mindpal-ui';
import type {
  DialogContentProps,
  DialogProps,
  DialogTriggerProps,
} from '@radix-ui/react-dialog';
import { useIsMutating } from '@tanstack/react-query';
import classNames from 'classnames';
import { AnimatePresence } from 'framer-motion';
import { useRouter } from 'next/router';
import {
  type FC,
  type PropsWithChildren,
  type ReactNode,
  useEffect,
} from 'react';
import ReactGA from 'react-ga4';

import { GAActions, GACategories } from '@/config/googleAnalytics';
import { useOnboarding } from '@/hooks/Onboarding/useOnboarding';
import { useSettingsOnboarding } from '@/hooks/Onboarding/useSettingsOnboarding';
import { useSettingsQuery } from '@/hooks/useSettingsQuery';
import { queries } from '@/queries';
import { getStringParam } from '@/utils/queryParams';

import Joyride from '../Joyride';
import LoadingPlaceholder from '../LoadingPlaceholder';
import SavingAnimation from '../SavingAnimation';
import Text from '../Text';
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTrigger,
} from '../ui/dialog';
import { ToggleGroup, ToggleItem } from '../ui/toggle-group';
import { TooltipContent } from '../ui/tooltip';
import SumTimeExperienceFormat from './ SumTimeExperienceFormat';
import Date from './Date';
import HardSkills from './HardSkills';
import Language from './Language';
import Template from './Template';

type Props = {
  dialogTriggerProps?: DialogTriggerProps;
  dialogContentProps?: DialogContentProps;
  dialogProps?: DialogProps;
  className?: string;
  tooltipDisabled?: boolean;
};
type Settings = {
  name: string;
  render: ReactNode;
};
const RecruiterSettingsDialog: FC<PropsWithChildren<Props>> = ({
  className,
  dialogTriggerProps,
  dialogContentProps,
  dialogProps,
  children,
  tooltipDisabled,
}) => {
  const { replace, query } = useRouter();
  const isMutating = useIsMutating({
    mutationKey: queries.settings.mine.queryKey,
  });
  const { data, isLoading } = useSettingsQuery();
  const { mutate } = useOnboarding();
  const [{ steps, run }, setState] = useSettingsOnboarding();
  const settings: Settings[] = [
    {
      name: 'Date',
      render: <Date data={data} key="Date" />,
    },
    {
      name: 'Work experience',
      render: <SumTimeExperienceFormat data={data} />,
    },
    {
      name: 'Hard skills',
      render: <HardSkills data={data} />,
    },
    {
      name: 'Language',
      render: <Language data={data} />,
    },
    {
      name: 'Template',
      render: <Template data={data} />,
    },
  ];

  const currentSetting = settings.find(
    ({ name }) => name === getStringParam(query.setting)
  );
  const handleOnChangeSetting = (newQuery: string | undefined) => {
    return replace({
      query: { ...query, setting: newQuery },
    });
  };

  const isRecruiterSettingsDialogOpen =
    !!query.setting &&
    settings.some(({ name }) => name === (getStringParam(query.setting) ?? ''));

  useEffect(() => {
    if (run && isRecruiterSettingsDialogOpen)
      setTimeout(() => {
        document.body.style.pointerEvents = '';
      }, 0);
  }, [run, isRecruiterSettingsDialogOpen]);
  return (
    <TooltipProvider delayDuration={0}>
      <Dialog
        {...dialogProps}
        open={isRecruiterSettingsDialogOpen}
        onOpenChange={(querySetting) => {
          if (!querySetting) handleOnChangeSetting(undefined);
          else {
            ReactGA.event({
              category: GACategories.SETTINGS,
              action: GAActions.OPEN_SETTINGS,
            });
            handleOnChangeSetting(settings[0].name);
          }
        }}
      >
        <Tooltip>
          <DialogTrigger asChild {...dialogTriggerProps} className={className}>
            <TooltipTrigger asChild>{children}</TooltipTrigger>
          </DialogTrigger>
          <DialogContent
            data-joyride="settings-dialog"
            {...dialogContentProps}
            className="h-3/4 w-full max-w-6xl overflow-auto"
          >
            {isLoading && <LoadingPlaceholder />}
            {data && (
              <AnimatePresence>
                <div className="flex flex-col gap-4">
                  <Joyride
                    onFinished={() => {
                      setState((prev) => ({ ...prev, run: false }));
                      mutate('SETTINGS');
                    }}
                    disableScrollParentFix
                    steps={steps}
                    run={run}
                  />
                  <DialogHeader className="border-b border-neutral-400 pb-5 text-neutral-1000">
                    <Text className="font-semibold" variant="h4">
                      Settings
                    </Text>
                  </DialogHeader>
                  <ToggleGroup
                    className="mb-2"
                    type="single"
                    value={getStringParam(query.setting)}
                    variant="secondary"
                    onValueChange={handleOnChangeSetting}
                  >
                    {settings.map(({ name }) => (
                      <ToggleItem key={name} value={name}>
                        {name}
                      </ToggleItem>
                    ))}
                  </ToggleGroup>
                  {currentSetting && currentSetting.render}
                </div>
                <SavingAnimation
                  isDark
                  key="saving-animation"
                  animationTrigger={!!isMutating}
                  successSavedMessage="Settings saved"
                />
              </AnimatePresence>
            )}
          </DialogContent>
          <TooltipContent
            side="right"
            className={classNames('rounded-lg p-2', {
              hidden: tooltipDisabled,
            })}
          >
            <Text variant="body-small" className="text-neutral-1000">
              Resume settings
            </Text>
          </TooltipContent>
        </Tooltip>
      </Dialog>
    </TooltipProvider>
  );
};

export default RecruiterSettingsDialog;
