import { useQuery } from '@tanstack/react-query';

import type { UseQueryFactoryProps } from '@/queries';
import { queries } from '@/queries';

export const useSettingsQuery = (
  props?: UseQueryFactoryProps<typeof queries.settings.mine>
) =>
  useQuery({
    ...queries.settings.mine,
    staleTime: Infinity,
    ...props,
  });
