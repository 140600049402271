import { useQuery } from '@tanstack/react-query';

import type { UseQueryFactoryProps } from '@/queries';
import { queries } from '@/queries';

export const useCVContext = (
  id?: number,
  queryProps?: UseQueryFactoryProps<
    ReturnType<typeof queries.cvGenerator.detail>
  >,
  isDownloadButton?: boolean
) => {
  return useQuery({
    ...queries.cvGenerator.detail(id!, isDownloadButton),
    enabled: !!id,
    cacheTime: 0,
    ...queryProps,
  });
};
