import { useMutation, useQueryClient } from '@tanstack/react-query';

import { queries } from '@/queries';
import type { Profile } from '@/services/profile';
import { postOnboardingAccomplished } from '@/services/profile';

const profileOnboardingKeys = {
  JOB_MATCHING: 'jobMatchingOnboardingAccomplished',
  DASHBOARD: 'dashboardOnboardingAccomplished',
  GENERATOR: 'generatorOnboardingAccomplished',
  COLLECTIONS: 'collectionsOnboardingAccomplished',
  SETTINGS: 'settingsOnboardingAccomplished',
};

export const useOnboarding = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: postOnboardingAccomplished,
    mutationKey: ['onboardingAccomplished'],
    onMutate: async (onboardingKey) => {
      await queryClient.cancelQueries({
        queryKey: queries.profile.me.queryKey,
      });

      const previousData = queryClient.getQueryData(
        queries.profile.me.queryKey
      );

      queryClient.setQueryData<Profile>(queries.profile.me.queryKey, (old) => ({
        ...old!,
        [profileOnboardingKeys[onboardingKey]]: true,
      }));

      return { previousData };
    },
    onError: (_err, _newData, context) => {
      queryClient.setQueryData(
        queries.profile.me.queryKey,
        context?.previousData
      );
    },
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: queries.profile.me.queryKey });
    },
  });
};
