import { useQuery } from '@tanstack/react-query';
import { useRouter } from 'next/router';

import { queries } from '@/queries';
import { getBooleanParam, getNumberParams } from '@/utils/queryParams';

import { useCVContext } from './useCVContext';
import { useExternalCandidate } from './useExternalCandidate';

export const useBreadcrumbsCustomPaths = () => {
  const router = useRouter();
  const { id, onboarding } = router.query;
  const parsedId = getNumberParams(id);
  const parsedOnboarding = getBooleanParam(onboarding);

  const { data } = useExternalCandidate(parsedId ?? 0, {
    enabled: !!parsedId && router.pathname === '/dashboard/[id]',
  });
  const { data: cvData } = useCVContext(parsedId, {
    enabled: !!parsedId && router.pathname === '/dashboard/generator/[id]',
  });
  const { data: jobMatchingData } = useQuery({
    ...queries.jobMatching.detail(parsedId!, parsedOnboarding),
    staleTime: Infinity,
    enabled: !!parsedId && router.pathname === '/dashboard/job-matching/[id]',
  });

  const customPaths: Record<string, string | undefined> = {
    '/dashboard/generator/[id]': cvData?.name ?? 'Unknown',
    '/dashboard/job-matching/[id]': jobMatchingData?.title ?? 'Unknown',
    '/dashboard/[id]': data?.name ?? 'Unknown',
    '/dashboard/csv': 'Import from .CSV',
    '/dashboard': 'Home',
  };
  return { customPaths };
};
