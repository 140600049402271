import type { UseQueryResult } from '@tanstack/react-query';
import { useQuery } from '@tanstack/react-query';

import { getBlobFromUrl, getBlobsFromUrls } from '@/utils/file';

export function useBlobFromUrl(url?: string): UseQueryResult<Blob | null>;
export function useBlobFromUrl(url?: string[]): UseQueryResult<Blob[]>;
export function useBlobFromUrl<T extends string | string[]>(url?: T) {
  return useQuery({
    queryKey: ['blobFromUrl', url],
    queryFn: async () => {
      if (Array.isArray(url)) {
        return getBlobsFromUrls(url);
      }
      return url ? getBlobFromUrl(url) : null;
    },
    enabled: !!url,
  });
}
