import { useEffect, useRef, useState } from 'react';

export const useFileDrag = () => {
  const [isDragging, setIsDragging] = useState(false);
  const dragCounter = useRef(0);

  const handleDragIn = (event: DragEvent) => {
    dragCounter.current += 1;

    if (event.dataTransfer?.items && event.dataTransfer.items.length > 0) {
      setIsDragging(true);
    }
  };
  const handleDragOut = () => {
    dragCounter.current -= 1;
    if (dragCounter.current > 0) return;
    setIsDragging(false);
  };
  const handleDrop = (event: DragEvent) => {
    event.preventDefault();
    event.stopPropagation();
    setIsDragging(false);
    if (
      event.dataTransfer &&
      event.dataTransfer.files &&
      event.dataTransfer.files.length > 0
    ) {
      dragCounter.current = 0;
    }
  };

  useEffect(() => {
    window.addEventListener('dragenter', handleDragIn);
    window.addEventListener('dragleave', handleDragOut);
    window.addEventListener('drop', handleDrop);
    return () => {
      window.removeEventListener('dragenter', handleDragIn);
      window.removeEventListener('dragleave', handleDragOut);
      window.removeEventListener('drop', handleDrop);
    };
  });
  return { isDragging };
};
