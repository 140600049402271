import { Icon } from '@iconify/react';
import { type FC, useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import type { InferType } from 'yup';
import { boolean, object, string } from 'yup';

import { useSettings } from '@/hooks/useSettings';
import {
  type Settings,
  SkillTimeExperienceFormatOptions,
} from '@/services/settings';

import Label from '../Form/Fields/Label';
import { Select } from '../Select';
import { Accordion, AccordionContent, AccordionItem } from '../ui/accordion';
import { Switch } from '../ui/switch';

type Props = {
  data?: Settings;
};

const HardSkills: FC<Props> = ({ data }) => {
  const { mutate } = useSettings();
  const { control, watch } = useForm<InferType<typeof schema>>({
    defaultValues: {
      useAbbreviationsInSkills: data?.useAbbreviationsInSkills ?? false,
      skillTimeExperienceFormat: data?.skillTimeExperienceFormat ?? null,
      hideSkillsYearsOfExp: data?.hideSkillsYearsOfExp ?? false,
    },
  });

  useEffect(() => {
    if (
      JSON.stringify([
        data?.skillTimeExperienceFormat,
        data?.useAbbreviationsInSkills,
        data?.hideSkillsYearsOfExp,
      ]) !==
      JSON.stringify([
        watch('skillTimeExperienceFormat'),
        watch('useAbbreviationsInSkills'),
        watch('hideSkillsYearsOfExp'),
      ])
    ) {
      mutate(watch());
    }
  }, [JSON.stringify(watch())]);

  const isAbbreviationsEnable = watch('useAbbreviationsInSkills');
  const isSkillsYearsOfExpHidden = !watch('hideSkillsYearsOfExp');
  const options = [
    {
      value: SkillTimeExperienceFormatOptions.FLOAT_YEAR,
      label: 'Original',
      customLabelMenuElement: (
        <div>
          <p>Original</p>
          {!isAbbreviationsEnable && (
            <p className="opacity-80">e.g. 2.5 years</p>
          )}
          {isAbbreviationsEnable && <p className="opacity-80">e.g. 2.5 yrs</p>}
        </div>
      ),
    },
    {
      value: SkillTimeExperienceFormatOptions.YEAR_MONTH,
      label: 'Years and months',
      customLabelMenuElement: (
        <div>
          <p>Years and months</p>
          {!isAbbreviationsEnable && (
            <p className="opacity-80">e.g. 1.5 years → 1 year 6 months</p>
          )}
          {isAbbreviationsEnable && (
            <p className="opacity-80">e.g. 1.5 yr → 1 yr 6 mos</p>
          )}
        </div>
      ),
    },
    {
      value: SkillTimeExperienceFormatOptions.MONTH,
      label: 'Cumulative months',
      customLabelMenuElement: (
        <div>
          <p>Cumulative months</p>
          {!isAbbreviationsEnable && (
            <p className="opacity-80">e.g. 1.5 years → 15 months</p>
          )}
          {isAbbreviationsEnable && (
            <p className="opacity-80">e.g. 1.5 yrs → 15 mos</p>
          )}
        </div>
      ),
    },
    {
      value: SkillTimeExperienceFormatOptions.YEAR_ROUND,
      label: 'Round to nearest year',
      customLabelMenuElement: (
        <div>
          <p>Year round</p>
          {!isAbbreviationsEnable && (
            <p className="opacity-80">
              e.g. 0.5 years → 1 year | 2.3 years → 2 year
            </p>
          )}
          {isAbbreviationsEnable && (
            <p className="opacity-80">e.g. 0.5 yrs → 1 yr | 2.3 yrs → 2 yrs</p>
          )}
        </div>
      ),
    },
    {
      value: SkillTimeExperienceFormatOptions.YEAR_ROUND_UP,
      label: 'Round up to full year',
      customLabelMenuElement: (
        <div>
          <p>Round up to full year</p>
          {!isAbbreviationsEnable && (
            <p className="opacity-80">e.g. 0.75 years → 1 year</p>
          )}
          {isAbbreviationsEnable && (
            <p className="opacity-80">e.g. 0.75 yrs → 1 yr</p>
          )}
        </div>
      ),
    },
    {
      value: SkillTimeExperienceFormatOptions.YEAR_ROUND_DOWN,
      label: 'Round down to full year',
      customLabelMenuElement: (
        <div>
          <p>Round down to full year</p>
          {!isAbbreviationsEnable && (
            <p className="opacity-80">e.g. 1.5 years → 1 year</p>
          )}
          {isAbbreviationsEnable && (
            <p className="opacity-80">e.g. 1.5 yrs → 1 yr</p>
          )}
        </div>
      ),
    },
  ];
  return (
    <form className="flex w-fit flex-col gap-6">
      <Controller
        control={control}
        name="hideSkillsYearsOfExp"
        render={({ field: { value, onChange } }) => (
          <div className="flex flex-row items-center gap-2">
            <Icon
              icon="material-symbols:work-history-outline"
              className="text-2xl text-neutral-900"
            />
            <Label className="text-neutral-900">
              Hidden time of experience in skills
            </Label>
            <Switch checked={value} onCheckedChange={onChange} />
          </div>
        )}
      />
      <Accordion
        type="single"
        value={isSkillsYearsOfExpHidden ? 'open' : undefined}
      >
        <AccordionItem value="open" className="border-b-0">
          <AccordionContent>
            <div className="flex flex-col gap-4 text-neutral-1000">
              <Controller
                control={control}
                name="skillTimeExperienceFormat"
                render={({ field: { name, onChange, value, onBlur } }) => (
                  <Select
                    name={name}
                    label="Display format"
                    className="max-w-[27rem]"
                    variant="light"
                    sizeVariant="sm"
                    onChange={(newValue) => onChange(newValue?.value)}
                    value={options.find((option) => option.value === value)}
                    onBlur={onBlur}
                    options={options}
                  />
                )}
              />
              <div className="flex w-full flex-col gap-2 rounded-lg bg-neutral-200 px-5 py-3">
                <Label variant="secondary" className="!p-0 text-neutral-800">
                  Use Abbreviations
                </Label>
                <Controller
                  control={control}
                  name="useAbbreviationsInSkills"
                  render={({ field: { value, onChange } }) => (
                    <div className="flex flex-row items-center justify-between gap-2">
                      <Label className="text-sm text-neutral-900">
                        e.g. 2 years 6 months → 2 yrs 6 mos
                      </Label>
                      <Switch checked={value} onCheckedChange={onChange} />
                    </div>
                  )}
                />
              </div>
            </div>
          </AccordionContent>
        </AccordionItem>
      </Accordion>
    </form>
  );
};

export default HardSkills;

const schema = object({
  hideSkillsYearsOfExp: boolean().required(),
  useAbbreviationsInSkills: boolean().required(),
  skillTimeExperienceFormat: string()
    .nullable()
    .defined()
    .oneOf([...Object.values(SkillTimeExperienceFormatOptions), null, '']),
});
