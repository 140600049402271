import * as SwitchPrimitives from '@radix-ui/react-switch';
import type { VariantProps } from 'class-variance-authority';
import { cva } from 'class-variance-authority';
import * as React from 'react';

import { cn } from '@/lib/utils';

const switchRootStyles = cva('', {
  variants: {
    variant: {
      primary:
        'peer inline-flex shrink-0 cursor-pointer items-center rounded-full border-transparent transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-slate-950 focus-visible:ring-offset-1 focus-visible:ring-offset-white disabled:cursor-not-allowed disabled:opacity-50 data-[state=checked]:bg-primary-600 data-[state=unchecked]:bg-[#E7DEFF]',
    },
    size: {
      sm: 'border-1 h-4 w-7',
      md: ' h-6 w-11 border-2',
    },
  },
  defaultVariants: {
    variant: 'primary',
    size: 'md',
  },
});
type SwitchRootProps = VariantProps<typeof switchRootStyles>;

const switchThumbStyles = cva('', {
  variants: {
    variant: {
      primary:
        'pointer-events-none block rounded-full bg-white ring-0 transition-transform',
    },
    size: {
      sm: 'aspect-square h-3 shadow-sm data-[state=checked]:translate-x-3.5 data-[state=unchecked]:translate-x-0.5',
      md: 'aspect-square h-5  shadow-lg data-[state=checked]:translate-x-5 data-[state=unchecked]:translate-x-0',
    },
  },
  defaultVariants: {
    variant: 'primary',
    size: 'md',
  },
});
type SwitchThumbProps = VariantProps<typeof switchThumbStyles>;

export interface SelectVariantProps extends SwitchThumbProps, SwitchRootProps {}

const Switch = React.forwardRef<
  React.ElementRef<typeof SwitchPrimitives.Root>,
  React.ComponentPropsWithoutRef<typeof SwitchPrimitives.Root> &
    SelectVariantProps
>(({ className, variant, size, ...props }, ref) => (
  <SwitchPrimitives.Root
    className={cn(switchRootStyles({ variant, size }), className)}
    {...props}
    ref={ref}
  >
    <SwitchPrimitives.Thumb
      className={cn(switchThumbStyles({ variant, size }))}
    />
  </SwitchPrimitives.Root>
));
Switch.displayName = SwitchPrimitives.Root.displayName;

export { Switch };
