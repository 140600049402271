import { useQuery } from '@tanstack/react-query';
import { useState } from 'react';

import { queries } from '@/queries';

import Text from '../Text';
import ChangelogNavigation from './ChangelogNavigation';

const ChangelogLayout = () => {
  const { data } = useQuery({
    ...queries.changelog.list,
    staleTime: 10 * 60 * 1000,
  });
  const [currentChangelog, setCurrentChangelog] = useState<number | null>(
    data?.results[0]?.id ?? null
  );
  const { data: detailsData } = useQuery({
    ...queries.changelog.detail(currentChangelog!),
    enabled: !!currentChangelog,
  });

  return (
    <div className="flex flex-row overflow-auto">
      <ChangelogNavigation
        setCurrentChangelog={setCurrentChangelog}
        currentChangelog={currentChangelog}
        data={data}
        className="flex flex-2"
      />
      <div className="flex flex-5 flex-col bg-neutral-900">
        {detailsData?.img && (
          <div className="relative flex flex-4">
            <img
              src={detailsData?.img}
              alt=""
              className="object-cover object-center"
            />
          </div>
        )}
        <div className="scroll-transparent flex flex-1 flex-col items-start justify-start gap-2 overflow-auto bg-neutral-900 p-4">
          <Text variant="h5" className="font-semibold">
            {detailsData?.title}
          </Text>
          <Text variant="body-small">{detailsData?.description}</Text>
        </div>
      </div>
    </div>
  );
};

export default ChangelogLayout;
