export enum GACategories {
  RESUME_DOWNLOAD = 'resume_download',
  COLLECTIONS = 'collections',
  JOB_MATCHING = 'job_matching',
  CSV_IMPORT = 'csv_import',
  SETTINGS = 'settings',
  CHANGELOG = 'changelog',
  DASHBOARD = 'dashboard',
  EDITOR = 'editor',
}

export enum GAActions {
  DOWNLOAD_PDF = 'download_pdf',
  DOWNLOAD_DOCX = 'download_docx',
  DOWNLOAD_JSON = 'download_json',
  DOWNLOAD_QUICK = 'download_quick',
  ADD_COLLECTION = 'add_collection',
  EDIT_COLLECTION = 'edit_collection',
  DELETE_COLLECTION = 'delete_collection',
  OPEN_JOB_MATCHING_RESULT = 'open_job_matching_result',
  OPEN_CSV_IMPORT = 'open_csv_import',
  START_CSV_IMPORT = 'start_csv_import',
  FINISH_CSV_IMPORT = 'finish_csv_import',
  OPEN_SETTINGS = 'open_settings',
  OPEN_CHANGELOG = 'open_changelog',
  SET_DASHBOARD_GRID_LAYOUT = 'set_dashboard_grid_layout',
  SET_DASHBOARD_LIST_LAYOUT = 'set_dashboard_list_layout',
  OPEN_ADJUST_TO_OFFER = 'open_adjust_to_offer',
}
