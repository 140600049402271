import {
  Tooltip,
  TooltipArrow,
  TooltipContent as TooltipContentBase,
  TooltipProvider,
  TooltipTrigger,
} from '@mindpal-co/mindpal-ui';
import type { ComponentPropsWithoutRef, ElementRef } from 'react';
import { forwardRef } from 'react';

import { cn } from '@/lib/utils';

const TooltipContent = forwardRef<
  ElementRef<typeof TooltipContentBase>,
  ComponentPropsWithoutRef<typeof TooltipContentBase> & { showArrow?: boolean }
>(
  (
    { className, sideOffset = 4, children, showArrow = true, ...props },
    ref
  ) => (
    <TooltipContentBase
      ref={ref}
      sideOffset={sideOffset}
      className={cn(
        'border-primary-36% bg-primary-20% text-white rounded-none',
        className
      )}
      {...props}
    >
      {children}
      {showArrow && <TooltipArrow className="fill-white" />}
    </TooltipContentBase>
  )
);

TooltipContent.displayName = 'TooltipContent';

export { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger };
