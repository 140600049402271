import { faCheck } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import type { VariantProps } from 'class-variance-authority';
import { cva } from 'class-variance-authority';
import classNames from 'classnames';
import type { Dispatch, SetStateAction } from 'react';
import { Fragment } from 'react';

import Button from '@/components/Button';
import Text from '@/components/Text';

type DividerStylesProps = VariantProps<ReturnType<typeof dividerStyles>>;

const dividerStyles = (status: 'completed' | 'inprogress' | 'notstarted') =>
  cva('h-[2px] w-full', {
    variants: {
      variant: {
        settings: 'bg-primary-100',
        csvDark: classNames({
          'bg-neutral-300': status === 'notstarted',
          'bg-gradient-to-r from-primary-600 from-50% to-neutral-400 to-50%':
            status === 'inprogress',
          'bg-success': status === 'completed',
        }),
        csvLight: classNames({
          'bg-neutral-300': status === 'notstarted',
          'bg-gradient-to-r from-primary-600 from-50% to-neutral-400 to-50%':
            status === 'inprogress',
          'bg-success': status === 'completed',
        }),
      },
    },
    defaultVariants: {
      variant: 'settings',
    },
  });

type StepStylesProps = VariantProps<ReturnType<typeof stepStyles>>;

const stepStyles = (status: 'completed' | 'inprogress' | 'notstarted') =>
  cva('flex aspect-square h-11 items-center justify-center rounded-full', {
    variants: {
      variant: {
        settings: ' bg-primary-600',
        csvDark: classNames({
          'bg-transparent border-2 border-primary-600': status === 'inprogress',
          'bg-success': status === 'completed',
          'bg-neutral-400': status === 'notstarted',
        }),
        csvLight: classNames({
          'bg-transparent border-2 border-primary-600': status === 'inprogress',
          'bg-success': status === 'completed',
          'bg-neutral-400': status === 'notstarted',
        }),
      },
    },
    defaultVariants: { variant: 'settings' },
  });

type StepLabelStylesProps = VariantProps<ReturnType<typeof stepLabelStyles>>;

const stepLabelStyles = (status: 'completed' | 'inprogress' | 'notstarted') =>
  cva('', {
    variants: {
      variant: {
        settings: 'text-neutral-1000',
        csvDark: classNames({
          'text-neutral-100': status === 'inprogress' || status === 'completed',
          'text-neutral-400': status === 'notstarted',
        }),
        csvLight: classNames({
          'text-neutral-1000':
            status === 'inprogress' || status === 'completed',
          'text-neutral-400': status === 'notstarted',
        }),
      },
    },
    defaultVariants: { variant: 'settings' },
  });

interface SelectVariantProps
  extends StepStylesProps,
    DividerStylesProps,
    StepLabelStylesProps {}

const StepNumberAndName = ({
  currentIndex,
  steps,
  setStep,
  variant = 'settings',
  disableStepButtons,
}: {
  currentIndex: number;
  steps: string[];
  setStep: Dispatch<SetStateAction<number>>;
  disableStepButtons?: boolean;
} & SelectVariantProps) => {
  const getStatus = (index: number, offset: number = 0) => {
    if (index === currentIndex + offset) return 'inprogress';
    if (index < currentIndex + offset) return 'completed';
    return 'notstarted';
  };

  const getStepLabelContent = (index: number) => {
    const status = getStatus(index, -1);

    if (variant === 'csvDark') {
      if (status === 'completed')
        return (
          <FontAwesomeIcon size="2x" className="text-black" icon={faCheck} />
        );
      if (status === 'notstarted')
        return (
          <Text className="font-semibold leading-3 text-black" variant="h5">
            {index + 1}
          </Text>
        );
      return (
        <Text className="font-semibold leading-3" variant="h5">
          {index + 1}
        </Text>
      );
    }
    if (variant === 'csvLight') {
      if (status === 'completed')
        return (
          <FontAwesomeIcon size="2x" className="text-black" icon={faCheck} />
        );
      if (status === 'notstarted')
        return (
          <Text className="font-semibold text-neutral-100" variant="h5">
            {index + 1}
          </Text>
        );
      return (
        <Text className="font-semibold text-neutral-1000" variant="h5">
          {index + 1}
        </Text>
      );
    }

    return (
      <Text className="font-semibold" variant="h5">
        {index + 1}
      </Text>
    );
  };
  return (
    <div className="mb-4 flex w-full flex-row">
      {steps.map((step, index) => (
        <Fragment key={step}>
          {index !== 0 && (
            <div className="flex h-11 w-full items-center justify-center">
              <div
                className={dividerStyles(getStatus(index))({
                  variant,
                })}
              />
            </div>
          )}
          <div className="flex w-full flex-col items-center justify-start gap-2">
            <div
              className={classNames(
                stepStyles(getStatus(index, -1))({
                  variant,
                })
                // {
                //   'opacity-30': currentIndex < index + 1,
                // }
              )}
            >
              <Button
                disabled={currentIndex < index + 1 || disableStepButtons}
                onClick={() => setStep(index + 1)}
                variant="unstyled"
                size="sm"
                className={classNames(
                  'disabled:!text-neutral-100 disabled:!opacity-100',
                  {
                    '!cursor-default': disableStepButtons,
                  }
                )}
              >
                {getStepLabelContent(index)}
              </Button>
            </div>
            <div>
              <Text
                className={stepLabelStyles(getStatus(index, -1))({ variant })}
              >
                {step}
              </Text>
            </div>
          </div>
        </Fragment>
      ))}
    </div>
  );
};

export default StepNumberAndName;
