import { initializeApp } from 'firebase/app';
import { getDownloadURL, getStorage, ref, uploadBytes } from 'firebase/storage';

const firebaseConfig = {
  apiKey: 'AIzaSyB37A8-Z5BDEQWs9_Vhhv4o8uH8ShO_iOI',
  authDomain: 'mindpal-8778a.firebaseapp.com',
  projectId: 'mindpal-8778a',
  storageBucket: 'mindpal-8778a.appspot.com',
  messagingSenderId: '106571832166',
  appId: '1:106571832166:web:8e7dcd93892891bfecc6a7',
  measurementId: 'G-B2MRN9PNW7',
};

const app = initializeApp(firebaseConfig);
const storage = getStorage(app);

export const uploadFileToFirebase = async (file: File | Blob) => {
  const originalFileName =
    file instanceof File ? file.name.split('.') : file.type.split('/');
  const extension = originalFileName.pop();
  const originalFileNameJoined = originalFileName.join('.');
  const fullName = [originalFileNameJoined, new Date().toISOString()].join('_');
  const storageRef = ref(storage, `${fullName}.${extension}`);
  const uploadTask = await uploadBytes(storageRef, file);
  const url = await getDownloadURL(uploadTask.ref);
  return url;
};
