import axios from 'axios';

export const getBlobFromUrl = async (url: string): Promise<Blob> => {
  const { data } = await axios.get(url, {
    responseType: 'blob',
  });
  return data;
};

export const getBlobsFromUrls = async (urls: string[]): Promise<Blob[]> => {
  const blobPromises = urls.map(async (url) => getBlobFromUrl(url));

  const blobs = await Promise.all(
    blobPromises.filter((blob) => blob !== null) as Promise<Blob>[]
  );

  return blobs;
};
