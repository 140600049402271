import { useQuery } from '@tanstack/react-query';

import type { UseQueryFactoryProps } from '@/queries';
import { queries } from '@/queries';

export const useExternalCandidate = (
  id: number,
  props?: UseQueryFactoryProps<
    ReturnType<typeof queries.externalCandidates.detail>
  >
) =>
  useQuery({
    ...queries.externalCandidates.detail(id),
    staleTime: Infinity,
    ...props,
  });
