import type { VariantProps } from 'class-variance-authority';
import { cva } from 'class-variance-authority';
import classNames from 'classnames';
import {
  type DetailedHTMLProps,
  type ForwardedRef,
  forwardRef,
  type TextareaHTMLAttributes,
  useState,
} from 'react';

import ErrorAccordion from '@/components/ErrorAccordion';
import Text from '@/components/Text';

import type { ErrorDetailsProps } from '../ArrayDataNestedForm';
import Label from './Label';

export type MultiLineInputStyles = VariantProps<typeof multiLineInputStyles>;
const multiLineInputStyles = cva(
  'scroll-transparent flex w-full rounded-lg p-4',
  {
    variants: {
      variant: {
        primary:
          'border-2 border-transparent bg-primary-900 outline-none focus:border-primary-500 disabled:cursor-not-allowed disabled:border disabled:border-neutral-700 disabled:bg-neutral-900',
        'neutral-1000':
          'border-2 border-transparent bg-neutral-1000 outline-none focus:border-neutral-500 disabled:cursor-not-allowed disabled:border disabled:border-neutral-700 disabled:bg-neutral-900',
        'primary-light':
          'border border-primary-500 bg-primary-700 outline-none focus:border-primary-300 disabled:cursor-not-allowed disabled:border disabled:border-neutral-500 disabled:bg-neutral-700',
        light:
          'border border-neutral-800 bg-neutral-100 text-neutral-1000 focus-within:border-primary-500',
        secondary:
          'border-2 border-transparent bg-secondary-500/20 outline-none focus:border-secondary-500',
        transparentNeutralBorder:
          '!rounded-l-lg !border border-neutral-500 bg-transparent text-neutral-1000',
        transparent:
          '!rounded-lg border border-primary-600 bg-neutral-100 text-black transition-colors placeholder:text-neutral-500 focus-within:border-neutral-300 hover:border-primary-900 active:bg-neutral-100 disabled:border-transparent',
        jobboardGrayBackgroud:
          '!rounded-md !border border-[#D9D9D9] bg-[#F9F9FB] !placeholder-[#636363]',
      },
      sizeVariant: {
        xs: 'py-2 text-body-small',
        base: 'py-3 text-body',
      },
      isError: {
        true: '!border-error !text-error',
      },
    },
    compoundVariants: [
      { isError: true, variant: 'primary', className: '!bg-error-36%' },
      { isError: true, variant: 'neutral-1000', className: '!bg-error-36%' },
    ],
    defaultVariants: {
      variant: 'primary',
      sizeVariant: 'base',
      isError: null,
    },
  }
);

type Props = DetailedHTMLProps<
  TextareaHTMLAttributes<HTMLTextAreaElement>,
  HTMLTextAreaElement
> &
  MultiLineInputStyles & {
    label?: string;
    labelClassName?: string;
    required?: boolean;
    characterLimit?: number;
    containerClassName?: string;
  } & ErrorDetailsProps<string>;

const MultiLineInput = (
  {
    label,
    className,
    canHaveErrorMessage = true,
    error,
    required,
    labelClassName,
    variant,
    sizeVariant,
    characterLimit,
    containerClassName,
    ...props
  }: Props,
  ref: ForwardedRef<HTMLTextAreaElement>
) => {
  const [value, setValue] = useState('');
  return (
    <div className="flex flex-col">
      <div className={classNames('flex flex-col gap-3', containerClassName)}>
        {label && (
          <Label
            required={required}
            htmlFor={props.name}
            className={labelClassName}
          >
            {label}
          </Label>
        )}
        <textarea
          className={classNames(
            multiLineInputStyles({
              variant,
              isError: !!error,
              sizeVariant,
            }),
            className
          )}
          rows={10}
          {...props}
          ref={ref}
          onChange={(newValue) => {
            if (props.onChange) props.onChange(newValue);
            setValue(newValue.target.value);
          }}
        />
        {characterLimit && (
          <Text
            variant="body-small"
            className={classNames('text-error text-end', {
              'text-neutral-1000': value.length < characterLimit,
            })}
          >
            {value.length}/{characterLimit}
          </Text>
        )}
      </div>
      {canHaveErrorMessage && <ErrorAccordion error={error} />}
    </div>
  );
};

export default forwardRef(MultiLineInput);
