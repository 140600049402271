import { useMutation, useQueryClient } from '@tanstack/react-query';

import { queries } from '@/queries';
import { patchSettings } from '@/services/settings';

export const useSettings = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: patchSettings,
    mutationKey: queries.settings.mine.queryKey,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: queries.settings.mine.queryKey,
      });
    },
  });
};
