import { type FC, useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import type { InferType } from 'yup';
import { object, string } from 'yup';

import { useSettings } from '@/hooks/useSettings';
import {
  type Settings,
  SumTimeExperienceFormatOptions,
} from '@/services/settings';

import { Select } from '../Select';

type Props = {
  data?: Settings;
};

const SumTimeExperienceFormat: FC<Props> = ({ data }) => {
  const { mutate } = useSettings();
  const { control, watch } = useForm<InferType<typeof schema>>({
    defaultValues: {
      sumTimeExperienceFormat: data?.sumTimeExperienceFormat ?? null,
    },
  });

  useEffect(() => {
    if (
      JSON.stringify(data?.sumTimeExperienceFormat) !==
      JSON.stringify(watch('sumTimeExperienceFormat'))
    ) {
      mutate(watch());
    }
  }, [JSON.stringify(watch())]);

  const options = [
    {
      value: SumTimeExperienceFormatOptions.YEAR_MONTH,
      label: 'Years and months',
      customLabelMenuElement: (
        <div>
          <p>Years and months</p>
          <p className="opacity-80">1 year 3 months</p>
        </div>
      ),
    },
    {
      value: SumTimeExperienceFormatOptions.MONTH,
      label: 'Cumulative months',
      customLabelMenuElement: (
        <div>
          <p>Cumulative months</p>
          <p className="opacity-80">e.g. 1 year 3 months → 15 months</p>
        </div>
      ),
    },
    {
      value: SumTimeExperienceFormatOptions.YEAR_ROUND,
      label: 'Round to nearest year',
      customLabelMenuElement: (
        <div>
          <p>Year round</p>
          <p className="opacity-80">
            e.g. 6 months → 1 year | 1 year 3 months → 1 year
          </p>
        </div>
      ),
    },
    {
      value: SumTimeExperienceFormatOptions.YEAR_ROUND_UP,
      label: 'Round up to full year',
      customLabelMenuElement: (
        <div>
          <p>Round up to full year</p>
          <p className="opacity-80">e.g. 9 months → 1 years</p>
        </div>
      ),
    },
    {
      value: SumTimeExperienceFormatOptions.YEAR_ROUND_DOWN,
      label: 'Round down to full year',
      customLabelMenuElement: (
        <div>
          <p>Round down to full year</p>
          <p className="opacity-80">e.g. 1 year 6 months → 1 years</p>
        </div>
      ),
    },
  ];
  return (
    <form>
      <Controller
        control={control}
        name="sumTimeExperienceFormat"
        render={({ field: { name, onChange, value, onBlur } }) => (
          <Select
            name={name}
            label="Display format"
            className="max-w-[27rem]"
            variant="light"
            sizeVariant="sm"
            onChange={(newValue) => onChange(newValue?.value)}
            value={options.find((option) => option.value === value)}
            onBlur={onBlur}
            options={options}
          />
        )}
      />
    </form>
  );
};

export default SumTimeExperienceFormat;

const schema = object({
  sumTimeExperienceFormat: string()
    .nullable()
    .defined()
    .oneOf([...Object.values(SumTimeExperienceFormatOptions), null, '']),
});
