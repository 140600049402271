import { useMutation } from '@tanstack/react-query';

import queryClient from '@/config/queryClient';
import { queries } from '@/queries';
import type { DjangoData } from '@/services/common';
import type { GetFromAPIRequestTemplate } from '@/services/settings';
import { deleteTemplateRequest } from '@/services/settings';

import { useOptimisticUpdate } from './useOptimisticUpdate';

export const useDeleteTemplateRequest = () => {
  const { setOptimisticData } = useOptimisticUpdate<
    DjangoData<GetFromAPIRequestTemplate>
  >(queries.settings.templateRequest._ctx.list.queryKey);

  const handleOptimisticUpdate = async (templateRequestToRemove: number) =>
    setOptimisticData((old) => ({
      ...old!,
      results: old!.results.filter(
        (singleTemplateRequest) =>
          singleTemplateRequest.id !== templateRequestToRemove
      ),
    }));

  return useMutation({
    mutationFn: deleteTemplateRequest,
    mutationKey: ['removeTemplate'],
    onMutate: handleOptimisticUpdate,
    onError: (_err, _newCollection, context) => {
      queryClient.setQueryData(
        queries.settings.templateRequest._ctx.list.queryKey,
        context?.previousData
      );
    },
  });
};
